<template>
    <div class="main">
        <div class="banner">
            <div class="banner-overlay"></div>
             <h1>A PROPOS<br /> DE NOUS</h1>
        </div>
        <section class="about">
            <div class="row">
                <h2 class="gradiant-text">La solution "MY-VIRTUAL-CONVENTION"<br /> a été imaginée, conçue et développée par SYNTHES'3D.</h2>
                <p> 
                    SYNTHES'3D est un studio digital qui, depuis 2007, conçoit et produit des outils et solutions digitales pour le compte de ses clients français et internationaux.
                </p>
                <p>
                    Spécialisé depuis sa création dans la 3D et le Web, il était légitime pour nous que nous concevions et commercialisons une solution de salons virtuels. Notre expérience dans le domaine remonte à 2013 lorsque nous avions lancé une 1ère mondiale : l'OrthoVirtualConvention.com : Le 1er congrès virtuel de l'Orthopédie.
                </p>
            </div>
        </section>
        <section class="about-s3d">
            <div class="sep sep-top"></div>
            <div class="sep sep-bottom"></div>
            <div class="row">
                <div class="col33">
                    <div class="about-item">
                        <img src="./../assets/icone_studio_digital.png" alt="studio digital" />
                        <div class="about-info">
                            <h3>Studio digital Français</h3>
                            <p>Expertises principales : 3D, Motion design, Réalité Virtuelle, Applications mobiles & Web</p>
                        </div>
                    </div>          
                </div>
                <div class="col33">
                    <div class="about-item">
                        <img src="./../assets/icone_studio_10ans.png" alt="studio digital" />
                        <div class="about-info">
                            <h3>Plus de 10 ans d’existence</h3>
                            <p>Créé en 2007 en France par Vivien Poujade pendant ses études d’ingénieur</p>
                        </div>
                    </div>          
                </div>
                <div class="col33">
                    <div class="about-item">
                        <img src="./../assets/icone_studio_aixangers-1.png" alt="studio digital" />
                        <div class="about-info">
                            <h3>Aix-en-Provence & Angers</h3>
                            <p>L’ensemble de notre équipe de production se répartie sur 2 établissements</p>
                        </div>
                    </div>          
                </div>
            </div>
            <!-- row 2 -->
            <div class="row">
                <div class="col33">
                    <div class="about-item">
                        <img src="./../assets/icone_studio_industriemedical-1.png" alt="studio digital" />
                        <div class="about-info">
                            <h3>Industrie & Médical</h3>
                            <p>Nous avons 2 divisions principales qui opèrent pour l’Industrie et le Médical</p>
                        </div>
                    </div>          
                </div>
                <div class="col33">
                    <div class="about-item">
                        <img src="./../assets/icone_studio_industrie.png" alt="studio digital" />
                        <div class="about-info">
                            <h3>Division «Industrie»</h3>
                            <p>Nous travaillons avec tous les acteurs industriels, les fabricants de matériel & les inventeurs</p>
                        </div>
                    </div>          
                </div>
                <div class="col33">
                    <div class="about-item">
                        <img src="./../assets/icone_studio_medical.png" alt="studio digital" />
                        <div class="about-info">
                            <h3>Division «Médical»</h3>
                            <p>Nous collaborons avec tous les acteurs du monde médical et de la MedTech ainsi que les professionnels de santé</p>
                        </div>
                    </div>          
                </div>
            </div>
            <!-- row 3 -->
            <div class="row">
                <div class="col33">
                    <div class="about-item">
                        <img src="./../assets/icone_studio_bpi.png" alt="studio digital" />
                        <div class="about-info">
                            <h3>Soutenu par BPI France</h3>
                            <p>Nous investissons fortement en Recherche & Développement depuis 2012</p>
                        </div>
                    </div>          
                </div>
                <div class="col33">
                    <div class="about-item">
                        <img src="./../assets/icone_studio_export-1.png" alt="studio digital" />
                        <div class="about-info">
                            <h3>20% de notre CA à l’export</h3>
                            <p>Nous sommes animés par une forte ambition et dimension internationale</p>
                        </div>
                    </div>          
                </div>
                <div class="col33">
                    <div class="about-item">
                        <img src="./../assets/icone_studio_usa-1.png" alt="studio digital" />
                        <div class="about-info">
                            <h3>Présence aux USA</h3>
                            <p>Depuis 2013, nous avons une antenne commerciale en Floride</p>
                        </div>
                    </div>          
                </div>
            </div>
            <div class="row-btn">
                <a href="https://www.synthes3d.com/" target="_blank" class="btn btn-gradiant">En savoir plus</a>
            </div>
        </section>
    </div>
</template>


<script>
export default {
    name : 'About'
}
</script>

<style lang="scss" scoped>
    .banner {
        background-image : url('./../assets/bg-about.jpg');
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
    }

    .about {
        display: flex;
        justify-content: center;
        padding-top:80px;
        padding-bottom:20px;
    }

    .about .row, .about-s3d .row  {
        width: $boxWidth;
        max-width: 96%;       
    }
    
    .about .row{
        flex-direction: column;
    }

    .about h2 {
        text-transform:uppercase;
         margin-bottom:40px;
         margin-top:0;
    }

    .about .row p {
        font-size:18px;       
    }

    .about-s3d {
        display: flex;
        align-items: center;
        flex-direction: column;
        padding-top:100px;
        padding-bottom:100px;
        background:$backgroundGray;
        position: relative;
    }


    .about-item {
        display: flex;
        margin: 20px ;
    }

    .about-item img {
        width:80px;
        height:80px;
        margin-right:15px;
    }

    .about-info h3 {
        font-size:22px;
        font-weight:400;
        color:$mainColor;
        margin-top: 0;
        margin-bottom:10px;
    }

    .about-info p {
        font-size: 15px;
        line-height: 25px;
        margin:0;
    }

    .row-btn {
        margin-top:40px;
    }

    .btn-gradiant {
        box-shadow: -260px 0 130px -130px $blue inset; 
        width:260px;
    }

    .about-s3d .sep-bottom{
        background-image: url('./../assets/border-bottom-right-white.png');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }

    .about-s3d .sep-top{
        background-image: url('./../assets/border-top-left-white.png');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }

    @media screen and (max-width: 1025px) {
        .col33 {
            width: 100%;
        }

        .row {
            flex-direction: column;
        }

        .about .row {
            max-width: 90%;
            margin:0 5%;
        }
    }
</style>